.header {
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 1fr;
}

.checkoutGrid {
  grid-template-columns: 48px 1fr 48px;

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.advancedSearchGrid {
  @apply bg-white grid grid-rows-1;
  grid-template-columns: 48px 1fr 48px 48px;

  @media (min-width: 1024px) {
    grid-template-columns: 48px 1fr auto 48px 48px;
  }
}

@media print {
  .header {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding-top: 30px;
  }
}

.lang {
  position: relative;
  top: -0.25rem;
  left: -0.25rem;
}

.cart {
  position: relative;
  top: -0.6rem;
}

.badge {
  position: absolute;
  top: 0;
  left: calc(50% - 7px);
  transform: scale(0.7);
}
